









































































import { Component, Prop, Vue } from 'vue-property-decorator';

import Thumb from '@/components/Thumb/index.vue';
import Tooltip from '@/components/Tooltip/index.vue';
import Lock from '@/assets/icons/Lock.vue';

import { InstanceAmplitude } from '@/share/Util/Metrics/Metrics';
import ActiveModal from '@/share/Util/ActiveModal';

import { LIST_PLAN_ID } from '@/constant/PlansIDs';

import { Doubt as IDoubt } from '@/globalInterfaces/Doubts';

@Component({
  components: {
    Thumb,
    Tooltip,
    Lock
  }
})
export default class Doubt extends Vue {
  @Prop({ required: true }) doubt!: IDoubt;
  @Prop({ default: false }) showNameSubject!: boolean;
  @Prop({ default: true }) avatar!: boolean;
  @Prop({ default: true }) isPermission!: boolean;

  private setModal = new ActiveModal();

  get isPlanFree() {
    return this.$store.getters.profile?.plan?.planID === LIST_PLAN_ID.FREE;
  }

  counter(count: number) {
    if (count === 1) {
      return `${count} resposta`;
    }

    return `${count || 0} respostas`;
  }

  goToDoubtPage() {
    if (!this.isPermission) {
      this.setShowModal();

      return;
    }

    this.setTrackAmplitude(Number(this.doubt.ID));

    this.$router.push({
      name: 'ShowDoubts',
      params: {
        id: String(this.doubt.ID)
      }
    });
  }

  removeTags(content: string) {
    const body = content;
    const temp = document.createElement('div');
    temp.innerHTML = body;

    const sanitized = temp.textContent || temp.innerText;
    const length = 70;
    let trimmedString = sanitized.substring(0, length);

    if (trimmedString && sanitized.length > trimmedString.length) {
      trimmedString = `${trimmedString}...`;
    }

    return `${trimmedString}` || '...';
  }

  setTrackAmplitude(doubtID: number) {
    InstanceAmplitude.setTrack({
      eventName: 'component_click',
      additionalAttrs: {
        data: {
          component: 'Doubt',
          local: 'Ver',
          doubtID
        }
      }
    });
  }

  setShowModal() {
    if (this.isPlanFree) this.showModal('modalPlanUpdate');
    else this.showModal('modalNotContentPlan');
  }

  showModal(id: string) {
    this.setModal.activeModal(id);
  }
}
